var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problemList"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_box_left"},[_c('p',[_vm._v(_vm._s(_vm.matchDetails.title))]),_c('div',{staticClass:"state"},[(_vm.matchDetails.activ_status == 1)?_c('span',[_vm._v("进行中")]):_vm._e(),(_vm.matchDetails.activ_status == -1)?_c('span',[_vm._v("未开始")]):_vm._e(),(_vm.matchDetails.activ_status == -2)?_c('span',[_vm._v("已结束")]):_vm._e()]),(
              _vm.matchDetails.is_publish == 2 && _vm.matchDetails.activ_status == -1
            )?_c('span',{staticClass:"publishDel",on:{"click":_vm.delPublish}},[_vm._v("删除")]):_vm._e(),(
              _vm.matchDetails.user_id !== _vm.userInfo.id &&
              _vm.priexRoute !== 'teachPlanCourse'
            )?_c('div',{staticClass:"btns"},[(_vm.matchDetails.is_join == -2)?_c('span',{staticStyle:{"background":"#00957e"}},[_vm._v("免费")]):_vm._e(),(
                _vm.matchDetails.is_join == 0 &&
                _vm.matchDetails.activ_status != -2 &&
                _vm.matchDetails.type == 1
              )?_c('span',{staticStyle:{"background":"#f15153","cursor":"pointer"},on:{"click":_vm.goJoin}},[_vm._v("报名")]):_vm._e(),(
                _vm.matchDetails.is_join == 1 &&
                _vm.matchDetails.activ_status != -2 &&
                _vm.matchDetails.activ_status != 1 &&
                _vm.matchDetails.type == 1
              )?_c('span',{staticStyle:{"background":"#969696","cursor":"pointer"},on:{"click":_vm.outFilter}},[_vm._v("取消报名")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"details-right"},[(
              (_vm.userType === 2 ||
                _vm.userInfo.id === _vm.matchDetails.user_id ||
                _vm.matchDetails.log_id) &&
              _vm.priexRoute !== 'teachPlanCourse'
            )?_c('div',{staticClass:"details",staticStyle:{"margin-right":"10px"},on:{"click":_vm.clickDetails}},[_vm._v(" 查看答题详情 ")]):_vm._e(),_c('div',{staticClass:"title_box_right",staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.handleCommand(
                'feedback',
                _vm.matchDetails.title,
                _vm.matchDetails.type_title || '比赛',
                { id: _vm.matchDetails.id }
              )}}},[_c('i',{staticClass:"el-icon-edit-outline"}),_vm._v("反馈 ")])])]),_c('div',{staticClass:"info"},[_c('el-row',{attrs:{"gutter":0}},[_c('el-col',{attrs:{"xs":8,"sm":6,"md":4,"lg":5,"xl":4}},[_c('div',{staticClass:"grid-title"},[_vm._v("举办者")])]),_c('el-col',{attrs:{"xs":4,"sm":6,"md":8,"lg":8,"xl":10}},[_c('div',{staticClass:"grid-title"},[_vm._v(" "+_vm._s(_vm.matchDetails.type_title || "比赛")+"时间 ")])]),_c('el-col',{attrs:{"xs":4,"sm":6,"md":8,"lg":6,"xl":6}},[_c('div',{staticClass:"grid-title"},[_vm._v(" 距离"+_vm._s(_vm.matchDetails.type_title || "比赛")+" ")])]),(_vm.matchDetails.property != 1 || _vm.matchDetails.type != 2)?_c('el-col',{attrs:{"xs":8,"sm":6,"md":4,"lg":5,"xl":4}},[_c('div',{staticClass:"grid-title"},[_vm._v(" "+_vm._s(_vm.matchDetails.type_title || "比赛")+"时长 ")])]):_vm._e()],1),_c('el-row',{attrs:{"gutter":0}},[_c('el-col',{attrs:{"xs":8,"sm":6,"md":4,"lg":5,"xl":4}},[_c('div',{staticClass:"grid-content border"},[_vm._v(" "+_vm._s(_vm.matchDetails.user_name)+" ")])]),_c('el-col',{attrs:{"xs":4,"sm":6,"md":8,"lg":8,"xl":10}},[_c('div',{staticClass:"grid-content"},[_vm._v(" "+_vm._s(_vm.matchDetails.activ_time)+" ")])]),_c('el-col',{attrs:{"xs":4,"sm":6,"md":8,"lg":6,"xl":6}},[_c('div',{staticClass:"grid-content"},[_vm._v(" "+_vm._s(_vm.matchDetails.activ_status == 1 ? "进行中" : _vm.matchDetails.activ_status == -2 ? "已结束" : _vm.matchDetails.space_time)+" ")])]),(_vm.matchDetails.property != 1 || _vm.matchDetails.type != 2)?_c('el-col',{attrs:{"xs":8,"sm":6,"md":4,"lg":5,"xl":4}},[_c('div',{staticClass:"grid-content"},[_vm._v(" "+_vm._s(_vm.matchDetails.match_time)+" ")])]):_vm._e()],1)],1),_c('div',{staticClass:"demo-image__lazy",style:({
          textAlign:
            (_vm.matchDetails.images || []).length === 1 ? 'center' : null,
        })},_vm._l((_vm.matchDetails.images),function(v,i){return _c('el-image',{key:i,class:'img child-th-' + parseInt(i / 2),staticStyle:{"background-color":"#ebebeb"},attrs:{"fit":"contain","src":v,"lazy":"","preview-src-list":_vm.matchDetails.images},on:{"load":function($event){_vm.$tool.element_callback(
              _vm.$el.querySelectorAll('.el-image.child-th-' + parseInt(i / 2)),
              -1,
              (is, index, selector, $index, $selector) => {
                if (is && index !== $index) {
                  selector.style.height = $selector.offsetHeight + 'px';
                }
              }
            )}}})}),1),(_vm.matchDetails.content)?_c('div',{staticClass:"conten_box",domProps:{"innerHTML":_vm._s(_vm.matchDetails.content)}}):_vm._e(),(
          _vm.matchDetails.user_id === _vm.userInfo.id ||
          (_vm.matchDetails.activ_status == 1 && _vm.matchDetails.is_join != 0)
        )?_c('div',{staticClass:"btn"},[(
            (!_vm.matchDetails.log_id &&
              _vm.matchDetails.is_publish == 1 &&
              _vm.matchDetails.activ_status == 1 &&
              _vm.priexRoute !== 'teachPlanCourse') ||
            _vm.matchDetails.type == 3
          )?_c('div',{on:{"click":_vm.clickStart}},[_vm._v(" 开始答题 ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"content-right"},[_c('Advertising'),_c('HotRecommend',{staticClass:"margin"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }