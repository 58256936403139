<template>
  <div class="problemList">
    <div class="box">
      <div class="content-left">
        <div class="title_box">
          <div class="title_box_left">
            <p>{{ matchDetails.title }}</p>
            <div class="state">
              <span v-if="matchDetails.activ_status == 1">进行中</span>
              <span v-if="matchDetails.activ_status == -1">未开始</span>
              <span v-if="matchDetails.activ_status == -2">已结束</span>
            </div>
            <!-- <span v-if="matchDetails.is_join == 1" class="publish" @click="cancelPublish">取消报名</span> -->
            <!-- 更改前 -->
            <!-- <span
              v-if="
                matchDetails.is_publish == 2 && priexRoute !== 'teachPlanCourse'
              "
              class="publish"
              @click="publish"
              >发布</span
            > -->

            <!-- 更改后 -->
            <!-- <span
              v-if="
                matchDetails.is_publish == 2 && priexRoute == 'teachPlanCourse'
              "
              class="publish"
              @click="publish"
              >发布</span
            > -->
            <span
              v-if="
                matchDetails.is_publish == 2 && matchDetails.activ_status == -1
              "
              class="publishDel"
              @click="delPublish"
              >删除</span
            >
            <div
              class="btns"
              v-if="
                matchDetails.user_id !== userInfo.id &&
                priexRoute !== 'teachPlanCourse'
              "
            >
              <span
                style="background: #00957e"
                v-if="matchDetails.is_join == -2"
                >免费</span
              >
              <span
                style="background: #f15153; cursor: pointer"
                @click="goJoin"
                v-if="
                  matchDetails.is_join == 0 &&
                  matchDetails.activ_status != -2 &&
                  matchDetails.type == 1
                "
                >报名</span
              >
              <!-- <span style="background: #00957e;" v-if="matchDetails.is_join == 1&&matchDetails.activ_status != -2">已报名</span> -->
              <span
                style="background: #969696; cursor: pointer"
                @click="outFilter"
                v-if="
                  matchDetails.is_join == 1 &&
                  matchDetails.activ_status != -2 &&
                  matchDetails.activ_status != 1 &&
                  matchDetails.type == 1
                "
                >取消报名</span
              >
            </div>
          </div>
          <div class="details-right">
            <!-- <div
              style="margin-right: 10px"
              class="details"
              @click="clickDetails"
              v-if="
                (userType === 2 ||
                  ((userInfo.id === matchDetails.user_id ||
                    matchDetails.log_id) &&
                    matchDetails.activ_status == -2)) &&
                priexRoute !== 'teachPlanCourse'
              "
            >
              查看答题详情
            </div> -->
            <div
              style="margin-right: 10px"
              class="details"
              @click="clickDetails"
              v-if="
                (userType === 2 ||
                  userInfo.id === matchDetails.user_id ||
                  matchDetails.log_id) &&
                priexRoute !== 'teachPlanCourse'
              "
            >
              查看答题详情
            </div>

            <!-- <div
              style="margin-right: 10px"
              class="share"
              v-show="shareShow"
              v-clipboard:copy="path"
              v-clipboard:success="clickAdd"
            >
              <i class="el-icon-share icon"></i> 分享链接
            </div> -->

            <div
              style="margin: 0"
              class="title_box_right"
              @click="
                handleCommand(
                  'feedback',
                  matchDetails.title,
                  matchDetails.type_title || '比赛',
                  { id: matchDetails.id }
                )
              "
            >
              <i class="el-icon-edit-outline"></i>反馈
            </div>
          </div>
        </div>
        <div class="info">
          <el-row :gutter="0">
            <el-col :xs="8" :sm="6" :md="4" :lg="5" :xl="4">
              <div class="grid-title">举办者</div>
            </el-col>
            <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="10">
              <div class="grid-title">
                {{ matchDetails.type_title || "比赛" }}时间
              </div>
            </el-col>
            <el-col :xs="4" :sm="6" :md="8" :lg="6" :xl="6">
              <div class="grid-title">
                距离{{ matchDetails.type_title || "比赛" }}
              </div>
            </el-col>
            <el-col
              :xs="8"
              :sm="6"
              :md="4"
              :lg="5"
              :xl="4"
              v-if="matchDetails.property != 1 || matchDetails.type != 2"
            >
              <div class="grid-title">
                {{ matchDetails.type_title || "比赛" }}时长
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :xs="8" :sm="6" :md="4" :lg="5" :xl="4">
              <div class="grid-content border">
                {{ matchDetails.user_name }}
              </div>
            </el-col>
            <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="10">
              <div class="grid-content">
                {{ matchDetails.activ_time }}
              </div>
            </el-col>
            <el-col :xs="4" :sm="6" :md="8" :lg="6" :xl="6">
              <div class="grid-content">
                {{
                  matchDetails.activ_status == 1
                    ? "进行中"
                    : matchDetails.activ_status == -2
                    ? "已结束"
                    : matchDetails.space_time
                }}
              </div>
            </el-col>
            <el-col
              :xs="8"
              :sm="6"
              :md="4"
              :lg="5"
              :xl="4"
              v-if="matchDetails.property != 1 || matchDetails.type != 2"
            >
              <div class="grid-content">
                {{ matchDetails.match_time }}
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          class="demo-image__lazy"
          :style="{
            textAlign:
              (matchDetails.images || []).length === 1 ? 'center' : null,
          }"
        >
          <el-image
            fit="contain"
            :src="v"
            v-for="(v, i) in matchDetails.images"
            :key="i"
            lazy
            :preview-src-list="matchDetails.images"
            :class="'img child-th-' + parseInt(i / 2)"
            style="background-color: #ebebeb"
            @load="
              $tool.element_callback(
                $el.querySelectorAll('.el-image.child-th-' + parseInt(i / 2)),
                -1,
                (is, index, selector, $index, $selector) => {
                  if (is && index !== $index) {
                    selector.style.height = $selector.offsetHeight + 'px';
                  }
                }
              )
            "
          ></el-image>
        </div>

        <div
          class="conten_box"
          v-if="matchDetails.content"
          v-html="matchDetails.content"
        ></div>
        <div
          class="btn"
          v-if="
            matchDetails.user_id === userInfo.id ||
            (matchDetails.activ_status == 1 && matchDetails.is_join != 0)
          "
        >
          <div
            v-if="
              (!matchDetails.log_id &&
                matchDetails.is_publish == 1 &&
                matchDetails.activ_status == 1 &&
                priexRoute !== 'teachPlanCourse') ||
              matchDetails.type == 3
            "
            @click="clickStart"
          >
            开始答题
          </div>
        </div>
      </div>
      <div class="content-right">
        <Advertising></Advertising>
        <HotRecommend class="margin"></HotRecommend>
      </div>
    </div>
  </div>
</template>

<script>
import {
  matchInfo,
  join,
  cancel_join,
  publishBS,
  removeBS,
} from "@/api/match.js";
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  props: ["other_id", "priexRoute"],
  components: {
    Advertising,
    HotRecommend,
  },
  data() {
    return {
      path: "",
      id: "",
      matchDetails: {},
      bannerList: [],
      // shareShow: false,
      group_id: 0,
    };
  },
  created() {
    console.log(this.$router.currentRoute, "this.$router.currentRoute");
    this.group_id = this.$router.currentRoute.query.group_id;
  },
  methods: {
    routeUpdate() {
      this.path = window.document.location.href;
      this.id = this.other_id;
      this.getMatchDetails();
      console.log(this.priexRoute, "priexRoutepriexRoutepriexRoute");
    },
    // clickAdd() {
    //   this.$message.success("分享链接复制成功");
    // },
    //取消报名
    cancelPublish() {},
    //删除
    delPublish() {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeBS({ id: this.matchDetails.id }).then((res) => {
            this.$message.success("删除成功");
            // this.$router.push({
            //   path: "/me/study",
            //   query: { type: "删除比赛" },
            // });
            //  this.$router.go(-1)
            this.$router.back();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //发布
    publish() {
      publishBS({ id: this.matchDetails.id }).then((res) => {
        this.$message.success("发布成功");
        this.getMatchDetails();
      });
    },
    // 详情
    getMatchDetails() {
      matchInfo({ id: this.id })
        .then(({ data }) => {
          this.matchDetails = data.info;
          console.log(this.matchDetails, "this.matchDetailsthis.matchDetails");
          console.log(data, this.matchDetails.type_title + "详情");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 报名
    goJoin() {
      join({ id: this.id })
        .then((res) => {
          this.$message.success("报名成功");
          this.getMatchDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消报名
    outFilter() {
      cancel_join({ id: this.id })
        .then((res) => {
          this.$message.success("取消报名成功");
          this.getMatchDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 开始答题
    clickStart() {
      this.$handleRoute(
        { id: this.id, type: this.matchDetails.type_title,duration:1 },
        "/match/questAnswer",
        "_blank"
      );
      // this.$router.push({
      //   path: "/match/questAnswer",
      //   query: {
      //     id: this.id,
      //     type: this.matchDetails.type_title,
      //   },
      // });
    },
    clickDetails() {
      console.log(this.userType, "this.userType");
      console.log(this.user_id, "this.user_id");
      if (this.userType == 1 && this.matchDetails.user_id != this.user_id) {
        // this.$router.push({
        //   path: "/match/questAnswer",
        //   query: {
        //     id: this.id,
        //     state: "seeDetails",
        //     noTime: 1,
        //     log_id: this.matchDetails.log_id,
        //     type: this.matchDetails.type_title,
        //   },
        // });
        this.$router.push({
          path: "/match/tiDanRecord",
          query: {
            log_id: this.matchDetails.log_id,
            id: this.matchDetails.id,
            style:'考试',

          },
        });
        return;
      }
      // this.$handleRoute(
      //   {
      //     id: this.id,
      //     type: this.matchDetails.type_title,
      //     group_id:this.group_id,
      //   },
      //   "matchOverDetails"
      // );
      this.$handleRoute(
        {
          other_id: this.id,
          group_id:this.group_id,
          type: this.matchDetails.type_title,
        },
        "teachingMatchCensus"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.demo-image__lazy {
  .img {
    width: 49.5%;
    max-height: 400px;
    margin-bottom: 10px;
  }
  .img:nth-child(odd) {
    margin-right: 1%;
  }
}
.details-right {
  display: flex;
  align-items: center;
  .details {
    margin-right: 1rem;
  }
  ::v-deep {
    input {
      width: 0px;
      background: none;
      outline: none;
      border: none;
      border-color: #00957e;
      z-index: -1;
    }
    input:focus {
      border: none;
      border-color: #00957e !important;
    }
  }
}
.share {
  cursor: pointer;
}
.publish {
  background: #f15153;
  cursor: pointer;
  color: #fff;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 3px;
}
.publishDel {
  background: #f15153;
  cursor: pointer;
  color: #fff;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 3px;
  margin-left: 0.8rem;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_box_left {
        display: flex;
        align-items: center;
        flex: 1;

        p {
          font-size: 1rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #00957E;
          line-height: 50px;
          white-space: pre-wrap;
        }
        .btns {
          display: flex;
          span {
            margin-left: 15%;

            padding: 0.3rem 1rem;

            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            white-space: nowrap;
            // &:nth-child(1) {
            //   background: #00957e;
            // }
            // &:nth-child(2) {
            //   background: #f15153;
            // }
            // &:nth-child(3) {
            //   background: #969696;
            // }
          }
        }
        .state {
          margin-right: 30px;
          span {
            border-radius: 3px;
            border: 1px solid #ccc;
            margin-left: 15%;
            padding: 0.2rem 0.6rem;
            color: #999;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            white-space: nowrap;
          }
        }
      }
      .details {
        cursor: pointer;
        background-color: #00957e;
        padding: 0.3rem 1rem;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        white-space: nowrap;
      }
      .title_box_right {
        cursor: pointer;
        margin-left: 1rem;
      }
    }
    .info {
      margin: 2% 0 5%;
      .grid-title {
        background: #00957e;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        height: 34px;
      }
      .grid-content {
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        height: 47px;
        background-color: #fff;
        border: 1px solid #979797;
        border-left: 0px solid #979797;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .border {
        border-left: 1px solid #979797;
      }
    }
    .conten_box {
      margin-top: 2rem;
      background: #ffffff;
      border: 1px solid #979797;
    }
    .conten_box {
      padding: 2% 4%;
    }
    .btn {
      //  display: flex;
      // justify-content: flex-end;
      margin-top: 3%;
      // text-align: right;
      div {
        float: right;
        background: #00957e;
        border-radius: 2px;
        padding: 0.5% 2%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
  .content-right {
    width: 25%;
    .margin {
      margin-top: 10%;
    }
  }
}
</style>